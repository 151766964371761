import "./table.scss";

function clickRemoteLink(e) {
  document.querySelector(`#link_to_${e.dataset.object}`).click();
}

document.addEventListener("click", function tableRowClicked(event) {
  if (event.target.parentNode.classList.contains("remote_row_link")) {
    clickRemoteLink(event.target.parentNode);
  }
});

document.addEventListener("click", function tableRowClicked(event) {
  if (event.target.classList.contains("remote_row_link-profile")) {
    window.location = event.target.dataset.link
  }
});
