const costDeclarationAmount21 = document.getElementById('cost_declaration_amount_21');
const costDeclarationAmount9 = document.getElementById('cost_declaration_amount_9');
const costDeclarationAmount0 = document.getElementById('cost_declaration_amount_0');
const costDeclarationTotalAmount = document.getElementById('cost_declaration_total_amount');
const foreignCostDeclaration = document.getElementById('cost_declaration_foreign_cost_declaration');
const retourCheckbox = document.getElementById('travel_expenses_declaration_retour');
const retourCheckboxLabel = document.getElementById('travel_expenses_declaration_retour_label');
const addDestinationButton = document.getElementById('add-destination-button');

if (costDeclarationAmount21) {
  costDeclarationAmount21.addEventListener('change', event => {
    costDeclarationTotalAmount.value = (parseFloat(event.target.value) || 0) + (parseFloat(costDeclarationAmount9.value) || 0) + (parseFloat(costDeclarationAmount0.value) || 0)
  })
}

if (costDeclarationAmount9) {
  costDeclarationAmount9.addEventListener('change', event => {
    costDeclarationTotalAmount.value = (parseFloat(event.target.value) || 0) + (parseFloat(costDeclarationAmount21.value) || 0) + (parseFloat(costDeclarationAmount0.value) || 0)

  })
}

if (costDeclarationAmount0) {
  costDeclarationAmount0.addEventListener('change', event => {
    costDeclarationTotalAmount.value = (parseFloat(event.target.value) || 0) + (parseFloat(costDeclarationAmount21.value) || 0) + (parseFloat(costDeclarationAmount9.value) || 0)
  })
}

if (foreignCostDeclaration) {
  foreignCostDeclaration.addEventListener('change', event => {
    if (event.target.checked) {
      costDeclarationAmount21.parentElement.classList.add("d-none");
      costDeclarationAmount9.parentElement.classList.add("d-none");
      costDeclarationAmount0.parentElement.classList.add("d-none");
      costDeclarationTotalAmount.removeAttribute("readonly");
    } else {
      costDeclarationAmount21.parentElement.classList.remove("d-none");
      costDeclarationAmount9.parentElement.classList.remove("d-none");
      costDeclarationAmount0.parentElement.classList.remove("d-none");
      costDeclarationTotalAmount.readOnly = "true";
    }
  })
}

if (retourCheckbox) {
  retourCheckbox.addEventListener('change', event => {
    if (event.target.checked) {
      addDestinationButton.classList.add("d-none");
    } else {
      addDestinationButton.classList.remove("d-none");
    }
  })
}

document.addEventListener('DOMContentLoaded', function() {
  const destinationsContainer = document.getElementById('additional-destinations-container');
  const destinationTemplate = document.getElementById('destination-template');

  let destinationIndex = 0; // To keep track of unique indexes for each destination

  if (addDestinationButton) { 
    addDestinationButton.addEventListener('click', function() {
      // Clone the template
      retourCheckbox.classList.add("d-none");
      retourCheckboxLabel.classList.add("d-none");
      const newDestination = destinationTemplate.cloneNode(true);
      newDestination.style.display = 'block';

      // Update the field names to use unique indexes
      newDestination.innerHTML = newDestination.innerHTML.replace(/NEW_RECORD/g, destinationIndex);

      const requiredFields = newDestination.querySelectorAll('input');
      requiredFields.forEach(field => field.setAttribute('required', true));

      // Append the cloned template to the container
      destinationsContainer.appendChild(newDestination);

      // Increment the index for the next additional destination
      destinationIndex++;
    });
    destinationsContainer.addEventListener('click', function(event) {
      if (event.target && event.target.classList.contains('remove-destination-button')) {
        // Remove the specific destination field set
        event.target.closest('.additional-destination-fields').parentElement.remove();
        if (destinationsContainer.children.length < 1) {
          retourCheckbox.classList.remove("d-none");
          retourCheckboxLabel.classList.remove("d-none");
        };
      }
    });
  }
});

const costDeclarationForm = document.getElementById('cost-declaration-form');
if (costDeclarationForm) {
  costDeclarationForm.addEventListener('submit', function (event) {
    const fileInput = document.getElementById('cost_declaration_evidence');
    const file = fileInput.files[0];
  
    if (file && file.size > 3.5 * 1024 * 1024) { // 3.5 MB limit
      event.preventDefault();
      alert('Bestand is te groot. Bestand moet kleiner zijn dan 3.5 MB.');
    }
  });
}