import $ from "jquery";

import "trix"

import "@rails/actiontext"

import "bootstrap";

import "chartkick/chart.js"

import "v2/stylesheets/application.scss";

// ############## Components ##################

import "v2/components/navbar/navbar";

import "v2/components/sidebar/sidebar";

import "v2/components/calendar/calendar";

import "v2/components/table/table";

import "v2/components/filters/filters";

import "v2/components/profile_header/profile_header";

import "v2/components/case_mutations/index_tabs/index_tabs";

import "v2/components/case_mutations/new_mutation/new_mutation";

import "v2/components/work_registrations/new_work_registration";

import "v2/components/holiday_calendar/holiday_calendar";

// ############## Scripts ##################

import "v2/scripts/draggableModal";

import "v2/scripts/iframe";

import "v2/scripts/accessTag";

import "v2/scripts/actionText";

import "v2/scripts/checklists";

import "v2/scripts/confirmDeleteModal";

import "v2/scripts/documents";

import "v2/scripts/employeeSelector";

import "v2/scripts/eventListeners";

import "v2/scripts/fab";

import "v2/scripts/otpAttempt";

import "v2/scripts/overworks";

import "v2/scripts/pageLoader";

import "v2/scripts/rdw";

import "v2/scripts/saldoModal";

import "v2/scripts/schedule";

import "v2/scripts/sortable";

import "v2/scripts/tools";

import "v2/scripts/declarations";

require("@rails/ujs").start();

require("channels");

window.jQuery = $;
window.$ = $;

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration);

    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});
